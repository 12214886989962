.selectbox {
  border-radius: 0.5em;
}

.selectbox-formcontrol {
  width: 100%;
  margin: 0.5em 0 !important;
}

.paper {
  border-radius: 0.5em;
}

.selectbox-label {
  background-color: white;
  padding: 0 0.5em !important;
}