@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&family=Chau+Philomene+One:ital@0;1&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~leaflet/dist/leaflet.css";
@import "./css_variables.scss";

// Main Formatting //

html {
  height: 100%;
}

//This determines how much padding to give the cards at the bottom for the pagination bar
.content {
  padding-bottom: 3rem;
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  overflow-y: scroll;
}

// This should be used when we want to keep the format of some text such as descriptions
pre {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  white-space: pre-wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  font-size: 1rem;
  color: $blackFont-light;
}

col-* {
  padding: 0 5px 0 5px;
}

row {
  justify-content: space-between;
}

a {
  color: inherit !important;
}

ul > li > a {
  font-weight: bold;
  color: $green !important;
}

a:hover {
  text-decoration: none !important;
}

.hyperlink {
  color: $green !important;
  font-weight: bold;
  cursor: pointer;
}

.btn:focus,
.btn:active {
  outline: $blackFont-light !important;
  box-shadow: none;
}

// Used to add text to elements that are only needed for accessibility, hidden normally
.accessibility-item {
  display: none;
}

// Page, Header and Footer //

.dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}

.dropdown-item:hover {
  background-color: $lightGrey !important;
}

.dropdown-item.active {
  background-color: $lightGrey !important;
  color: $green !important;
  font-weight: bold;
}

.second-nav {
  background-color: $lightGrey;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42px; //container is 26, top and bottom padding is 8 each (=42)
  padding-top: 8px;
  border-top: solid darken($lightGrey, 5%) 1px;
  background-color: $lightGrey !important;
}

.footer-desktop-content {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.pageContainer {
  position: relative;
  min-height: 92vh;
  height: 100%;
}

#footer {
  position: absolute;
  bottom: 0;
}

.footer-text {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  cursor: pointer;
  padding-bottom: 8px;
  vertical-align: middle;
}

.footer-icon {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.page-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Attraction Cards //

.tilesmobile {
  object-fit: cover;
  width: 100%;
  height: 150px;
  align-items: top;
}

.tilesicon {
  top: 20%;
}

.force-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.attraction-description-force-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.card-image {
  height: 250px;
}

.attraction-image {
  object-fit: cover;
  width: 100%;
  align-items: top;
  height: 350px;
  @media only screen and (max-width: 767px) {
    height: 250px;
  }
}

.image-attribution {
  font-style: italic;
  color: $grey;
  font-size: 0.75rem;
  b,
  a {
    color: darken($grey, 20%);
  }
}

.attractions-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  align-items: top;
}

.card-body {
  padding: 0.8rem;
}

.attraction-card-body {
  padding: 0.8rem;
  margin-bottom: 4rem;
}

.card-footer {
  padding-top: 0;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  background: none;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-edge {
  transition: box-shadow 0.3s;
  box-shadow: 0 0 4px $grey;
  background: $white;
}

.card-edge:hover,
.tile-container:hover,
.attraction-group-tile:hover {
  box-shadow: 0 0 16px $grey;
  cursor: pointer;
}

.card-footer-text {
  font-size: 0.9rem;
  color: $green;
  font-weight: bold;
  display: inline-block;
}

// Filter Menus //

// Dropdown Menu Min Width
.MuiFormGroup-root {
  min-width: 13rem;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: darken($blackFont-dark, 25%) !important;
}

.filter-title {
  color: $blackFont-light;
}

.MuiFormControlLabel-root {
  margin-bottom: 0;
  margin-top: 0;
}

.MuiIconButton-label {
  margin: 0 0.5rem 0 0.5rem;
}

// Materials icon
.MuiSvgIcon-root {
  color: $green !important;
}

// MUI Click effect
.MuiTouchRipple-rippleVisible {
  color: $green !important;
}

.MuiColorPrimary {
  color: $green !important;
}

// Page Tabs Navbar //

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  padding-top: 0.3rem;
  background-color: $green;
  z-index: 1000;
}

.navbar-collapse {
  background-color: $green;
  width: 100%;
  // Makes navbar container span full width of device
  @media only screen and (max-width: 1140px) {
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    padding: 0rem 1rem 1rem 1rem;
  }
}

.pageNavButton {
  background-color: transparent;
  border: none;
}

.custom-nav-toggler {
  background-color: unset;
  color: $white;
  border: none;
  font-size: 1.5em;
}

.custom-nav-toggler:focus {
  border: none;
  outline: none;
}

.pageNavButton:focus {
  border: none;
  outline: none;
}

.pageNav {
  font-family: "Fredoka One", cursive, sans-serif !important;
  font-weight: lighter !important;
  color: $darkGrey;
  padding: 0.75rem 1rem 0.75rem 1rem;
}

.pageNavActive {
  color: $green !important;
  cursor: default;
  background-color: $white;
  border-bottom: 5px solid $green !important;
}

.nav-link {
  background: none !important;
}

// Info Pages //

.infoBody {
  background-color: $white;
  border: 1px solid $lightGrey;
}

.infoPanel {
  background-color: $white;
  text-align: center;
  margin: 1rem;
  border: 0.2rem solid $green;
  border-radius: 1rem;
}

.infoHr {
  border: 1px solid $green;
}

.infoBorder {
  border-top: none !important;
}

.contact-icon {
  font-size: 4rem;
  font-weight: bold;
}

.btn-bordered {
  border: 1px solid $border-light;
}

// Leaflet Map //

.leaflet-container {
  width: 100%;
  height: 30vh;
}

.maplabels,
.leaflet-control,
.leaflet-popup-close-button {
  color: $blackFont-light !important;
}

// Pagination //

.MuiPaginationItem-page.Mui-selected {
  background-color: $green !important;
  color: $white !important;
}

.makeStyles-root-1 > * {
  margin-top: 0 !important;
}

.paginationbar {
  z-index: 5;
  left: 0%;
  right: 0%;
  border: 2px solid $toolbarGreen;
  position: relative;
  border-bottom: 2px solid $toolbarGreen;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

// Attraction Rating Icons //

.rating-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.rating-icon {
  float: left;
  color: $darkGrey;
}

.rating-icon-success {
  float: left;
  color: darken($success, 10%);
}

.rating-icon-danger {
  float: left;
  color: darken($danger, 10%);
}

.rating-icon-active {
  float: left;
  color: $lightGrey;
}

.svg-inline--fa.fa-fw {
  width: 1rem;
}

// Other //

.region-card {
  background-color: $green;
}

.activeTab {
  font-weight: bold;
  color: $green !important;
  border-bottom: 5px solid $green;

  &:focus,
  &:active {
    border-bottom: 5px solid $green;
  }
}

//Cookie Notice //

.cookiesCard {
  position: fixed;
  z-index: 9999999;
  right: 8%;
  left: 8%;
  bottom: 0;
  max-height: 90%;
  border: solid $grey 1px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  float: left;
  display: block;
  background-color: $lightGrey !important;
}

.cookieCardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

// Form on/off toggle slider

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  padding: 0;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $lightGrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// Dark mode transition
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: 500ms !important;
  transition-delay: 0 !important;
}

button.noStyle,
button.noStyle:active,
button.noStyle:hover,
button.noStyle:focus {
  border: none;
  outline: none;
  background-color: unset;
  cursor: pointer;
}

.navbar {
  button {
    span {
      @media only screen and (min-width: 1140px) {
        display: none;
      }
    }
    //increased max hide res of navbar settings button here.
    span.mobile-only {
      @media screen and (max-width: 1140px) {
        display: inline;
      }
    }
  }
}

button#settings-button {
  @media screen and (min-width: 1140px) {
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 1140px) {
    width: 100%;
  }
}

.navbar-locationbox {
  border-left: none;
  cursor: pointer;
  background-color: $white;

  // Sets the location icon colour to blue
  svg {
    color: $blue;
  }
}

.modal {
  // This is just to make sure the modal shows below the navbar
  top: 4rem;
  z-index: 3000;
}

.modal-open {
  overflow-y: scroll !important;
}

/* Container holding the image and the text */
.tile-container {
  position: relative;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 4px #95a5a6;
  text-align: center;
  height: 150px;
}

/* Centered text */
.tile-text-centered {
  color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(21, 160, 132, 0.7);
  padding: 0 0.7em 0 0.7em;
  span,
  span.h1,
  span.h2,
  span.h3,
  span.h4,
  span.h5,
  span.h6,
  span b {
    color: $white;
    text-shadow: 0 0 5px $darkGrey;
  }
}

// centres navbar logo on navbar container
.navbar-brand {
  @media (max-width: 1024px) {
    transform: translateX(-50%);
    left: 50%;
    position: relative;
  }
}

//Increases max text size of navbar objects up to iPad in Landscape
.custom-nav-item {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
  color: $white;
  font-weight: 500;
  font-size: 1.15rem;
  @media (max-width: 1140px) {
    font-size: 25px !important;
  }
}

.clickable {
  cursor: pointer;
}

.btn.btn-xs {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: 0.75rem;
}

button.attraction-card {
  padding: 0;
  border: unset;
  background-color: $white;
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    text-align: left;
  }
}

.email-banner-container {
  background: $darkGrey;
  color: $white;
}

.add-attraction-banner-container {
  background: $purple;
  color: $white;
}

.retrieve-attraction-banner-container {
  background: $orange;
  color: $white;
}

.view-submission-banner-container {
  background: $blue;
  color: $white;
}

.home-banner-container {
  background: $green;
  color: $white;
}

.blur-image {
  -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
  filter: blur(1px);
}

.badge-success {
  background-color: $green;
}

.image-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 400px;
}

.banner-img {
  object-fit: cover;
  flex: 1;
  margin-right: 1rem;
  overflow: hidden;
  max-height: 400px;
}

.featured-banner-image-container {
  box-shadow: 0 0 4px $grey;
}

.tile-image-wrapper {
  width: 100%;
  height: 10vh;
  overflow: hidden;

  .blur-image {
    transition: box-shadow 0.3s;
    box-shadow: 0 0 4px $grey;
    width: 105%;
    height: 105%;
    margin: -5px;
    -webkit-filter: blur(1.5px);
    filter: blur(1.5px);
  }
}

.lazyload-wrapper {
  width: 100%;
  height: 100%;
}

.banner-text-background {
  background-color: $purple;
  color: $white;
  padding-bottom: 20px;
  padding-right: 20px;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  p {
    color: $white;
  }
}

em.attraction-list-results-text {
  color: darken($grey, 15%);
}

.missing-description {
  color: $info;
}

.featured-banner-container {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  background-color: $lightGrey;
}

.featured-tile-section-container {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  background-color: $lightGrey;
}

.email-banner-page-container {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.text-white {
  color: $white !important;
}

.text-green {
  color: $green !important;
}

.background-green {
  background-color: $green !important;
}

.text-blue {
  color: $blue !important;
}

.background-blue {
  background-color: $blue !important;
}

.text-purple {
  color: $purple !important;
}

.background-purple {
  background-color: $purple !important;
}

.text-darkgrey {
  color: $darkGrey !important;
}

.background-darkgrey {
  background-color: $darkGrey !important;
}

.text-grey {
  color: $grey !important;
}

.background-grey {
  background-color: $grey !important;
}

.text-lightgrey {
  color: $lightGrey !important;
}

.background-lightgrey {
  background-color: $lightGrey !important;
}

.text-red {
  color: $red !important;
}

.background-red {
  background-color: $red !important;
}

.text-orange {
  color: $orange !important;
}

.background-orange {
  background-color: $orange !important;
}

.text-yellow {
  color: $yellow !important;
}

.background-yellow {
  background-color: $yellow !important;
}

.modal-container {
  max-height: 70vh;
  @media only screen and (max-height: 850px) {
    max-height: 60vh;
  }
  overflow-y: auto;
  overflow-x: hidden;
}

.form-label,
.MuiFormLabel-root {
  color: $darkGrey !important;
  font-weight: bold !important;
  margin-bottom: 0.25rem;
}

.mui-selectlabel {
  color: lighten($darkGrey, 20%) !important;
  font-weight: normal !important;
}

.default-select-menuitem {
  color: darken($lightGrey, 10%) !important;
}

.forced-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.clear-input-button,
.clear-input-button:hover {
  color: $red;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.MuiAlert-action,
.MuiAlert-root {
  .MuiSvgIcon-root {
    fill: unset !important;
  }
}

@media (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomLeft,
  .MuiSnackbar-anchorOriginBottomRight,
  .MuiSnackbar-anchorOriginBottomCenter {
    bottom: 50px !important;
  }
}

.attractionFinderContainer {
  background-color: $lightGrey;
}

.featuredBanner-image {
  border-top-right-radius: 0;
  border-top-left-radius: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0%;
  width: 100%;
  height: 100%;
}

.wordbreak {
  word-break: break-word;
}

.apply-ellipsis-1 {
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
}

.apply-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.apply-ellipsis-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}

body.modal-open {
  padding-right: 0 !important;
}

.leaflet-popup-content {
  width: 55vw;
}