@import './css_variables.scss';

html[data-theme='dark'] {
        
    // Backgrounds
    body {
        background: $mainBkg-dark;
    }

    .modal-content {
        background: $mainBkg-dark;

        .close {
            color: $white;
        }
    }

    footer {
        background: $mainBkg-dark !important;
    }

    .card {
        background: $containerBkg-dark;
    }

    .card-edge {
        background: $containerBkg-dark;
    }

    .dropdown-menu {
        background: $containerBkg-dark;
        color: $blackFont-dark;
    }

    .dropdown-item:hover{
        color: $containerBkg-dark !important;
    }
    
    .second-nav {
        background: $secondaryBkg-dark;
    }

    .infoBody {
        background: $containerBkg-dark;
        border: 1px solid $border-dark;
    }

    .infoPanel {
        background: $containerBkg-dark;
    }

    .cookiesCard {
        background: $secondaryBkg-dark;
    }

    .pageNavActive {
        background: $containerBkg-dark;
    }

    // Text
    div:not(.alert) {
        color: $blackFont-dark;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $blackFont-dark !important;
    }

    .text-white {
        color: $whiteFont-dark !important;
    }

    .text-success {
        color: $green !important;
    }

    .card-footer-text {
        color: $blackFont-dark;
    }

    .table {
        color: $blackFont-dark;
    }

    .icon-text {
        color: $iconText-dark !important;
    }

    .footer-icon {
        color: $blackFont-dark !important;
    }
    .footer-text {
        color: $blackFont-dark !important;
    }

    .MuiPaginationItem-page {
        color: $white !important;
    }

    // Lines
    hr{
        border: 1px solid $border-dark !important;
    }

    // Inputs and Objects 
    .pageNav {
        color: $blackFont-dark;
    }

    // .mapLabels, .leaflet-control, .leaflet-popup-close-button {
    //     color: $GS8 !important;
    // }

    .activeTab {
        color: $green;
    }

    .slider {
        background-color: $secondaryBkg-dark;
    }

    .slider:before {
        background-color: $slider-dark;
    }

    input:checked + .slider {
        background-color: $green;
    }

    .filter-title {
        color: $blackFont-dark;
    }      

   // Button
    .btn-bordered {
        color: $btnBlackFont-dark;
    }

    button.attraction-card {
        background-color: $containerBkg-dark;
      }
}


// This is a transition for changing between the dark and ligth mode, but as the page reloads is rather pointless

// html.transition,
// html.transition *,
// html.transition *:before,
// html.transition *:after {
//   transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms!important;
//   transition-delay: 0!important;
// }