//Black and White
$white: #fff;
$black: #000;

//GreyScale
$GS10: #24292e;
$GS9: #383F45;
$GS8: #454c52;
$GS7: #596066;
$GS6: #676e76;
$GS5: #7f8790;
$GS4: #9ea5ad;
$GS3: #ced2d6;
$GS2: #e5e7ea;
$GS1: #f6f7f9;

//Main colour palette
$green: #15a084;
$toolbarGreen: rgba(21, 160, 132, 0.25);
$lightGreen: #27ae60;
$blue: #297fb9;
$purple: #8e44ad;
$darkGrey: #2c3e50;
$grey: #95a5a6;
$lightGrey: #ebf0f1;
$red: #e74b3c;
$orange: #e67e21;
$yellow: #f1c40d;

$background: #fafafa;

$primary: #0d6efd;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffcd39;
$info: #3dd5f3;
$light: #f8f9fa;
$dark: #212529;

// LIGHT THEME // These are currently not used, and instead are left to the defaults in the index.scss
//Background colours
$mainBkg-light: $background;
$secondaryBkg-light: $GS2;
$containerBkg-light: $white;
$inputboxBkg-light: $white;
//Font Colours
$blackFont-light: $GS8;
$whiteFont-light: $white;
$btnWhiteFont-light: #000;
$btnBlackFont-light: #000;
//Object Colours
$border-light: $GS3;
$slider-light: $white;


// DARK THEME //
//Background colours
$mainBkg-dark: $GS10;
$secondaryBkg-dark: $GS9;
$containerBkg-dark: $GS8;
$inputboxBkg-dark: $GS2;
//Font Colours
$blackFont-dark: $GS3;
$whiteFont-dark: $white;
$iconText-dark: $black;
$btnWhiteFont-dark: $GS3;
$btnBlackFont-dark: $GS3;
//Object Colours
$border-dark: $GS7;
$slider-dark: $GS8;



// Default
// --mainBkg: #{$background};
//   --secondNavBkg: #{$GS2};
//   --containerBkg: #{$white};
//   --offWhiteContainerBkg: #{$GS2};
//   --blackFont: #{$GS10};
//   --whiteFont: #{$white};
//   --btnWhiteFont: #{$white};
//   --borderColour: #{$GS3};
//   --inputBox: #{$white};
//   --inputText: #{$GS5};
//   --sliderColour: #{$white};