@import "./css_variables.scss";

.App,
footer {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 978px) {
  .page-margin {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.App-link {
  color: $blue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-success {
  color: $green !important;
}

.text-light {
  color: $grey !important;
}

.text-dark {
  color: $darkGrey !important;
}

.text-danger {
  color: $red !important;
}

.text-warning {
  color: $orange !important;
}

.text-info {
  color: $blue !important;
}

.text-primary {
  color: $purple !important;
}

.btn-success {
  background-color: $green !important;
  border-color: $green !important;
  color: $white !important;
}

.btn-dark {
  background-color: $darkGrey !important;
  border-color: $darkGrey !important;
  color: $white !important;
}

.btn-light {
  background-color: $lightGrey !important;
  border-color: $lightGrey !important;
  color: $darkGrey !important;
}

.btn-danger {
  background-color: $red !important;
  border-color: $red !important;
  color: $white !important;
}

.btn-warning {
  background-color: $orange !important;
  border-color: $orange !important;
  color: $white !important;
}

.btn-info {
  background-color: $blue !important;
  border-color: $blue !important;
  color: $white !important;
}

.btn-primary {
  background-color: $purple !important;
  border-color: $purple !important;
  color: $white !important;
}

.bg-success {
  background-color: $green !important;
}

.bg-light {
  background-color: $grey !important;
}

.bg-dark {
  background-color: $darkGrey !important;
}

.bg-danger {
  background-color: $red !important;
}

.bg-warning {
  background-color: $orange !important;
}

.bg-info {
  background-color: $blue !important;
}

.bg-primary {
  background-color: $purple !important;
}

.bg-offwhite {
  background-color: $lightGrey !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.apexcharts-title-text {
  font-family: "Fredoka One", cursive, sans-serif !important;
}

h1,
.h1 {
  font-size: 1.75rem;
}

h2,
.h2 {
  font-size: 1.25rem;
}

h3,
.h3 {
  font-size: 1.2rem;
}

h4,
.h4 {
  font-size: 1.15rem;
}

h5,
.h5 {
  font-size: 1.1rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.attractionContainerHeading {
  font-size: 1.1rem;
  font-weight: bold;
}

.attractionContainerSubHeading {
  margin-top: -0.8rem;
  font-size: 0.825rem;
  font-weight: bold;
}

#circle {
  width: 3rem;
  height: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 50%;
  background: $red;
}
